/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PriceOutputDto,
    PriceOutputDtoFromJSON,
    PriceOutputDtoFromJSONTyped,
    PriceOutputDtoToJSON,
} from './PriceOutputDto';

/**
 * 
 * @export
 * @interface ProductOutputDto
 */
export interface ProductOutputDto {
    /**
     * 
     * @type {string}
     * @memberof ProductOutputDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOutputDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOutputDto
     */
    unit: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOutputDto
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof ProductOutputDto
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof ProductOutputDto
     */
    maxAmount: number;
    /**
     * 
     * @type {string}
     * @memberof ProductOutputDto
     */
    portions: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOutputDto
     */
    about: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOutputDto
     */
    image: string;
    /**
     * 
     * @type {PriceOutputDto}
     * @memberof ProductOutputDto
     */
    price: PriceOutputDto;
}

export function ProductOutputDtoFromJSON(json: any): ProductOutputDto {
    return ProductOutputDtoFromJSONTyped(json, false);
}

export function ProductOutputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductOutputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'unit': json['unit'],
        'category': json['category'],
        'minAmount': json['minAmount'],
        'maxAmount': json['maxAmount'],
        'portions': json['portions'],
        'about': json['about'],
        'image': json['image'],
        'price': PriceOutputDtoFromJSON(json['price']),
    };
}

export function ProductOutputDtoToJSON(value?: ProductOutputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'unit': value.unit,
        'category': value.category,
        'minAmount': value.minAmount,
        'maxAmount': value.maxAmount,
        'portions': value.portions,
        'about': value.about,
        'image': value.image,
        'price': PriceOutputDtoToJSON(value.price),
    };
}

