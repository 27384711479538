/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeslotInputDto
 */
export interface TimeslotInputDto {
    /**
     * 
     * @type {number}
     * @memberof TimeslotInputDto
     */
    week: number;
    /**
     * 
     * @type {number}
     * @memberof TimeslotInputDto
     */
    year: number;
    /**
     * 
     * @type {boolean}
     * @memberof TimeslotInputDto
     */
    active: boolean;
}

export function TimeslotInputDtoFromJSON(json: any): TimeslotInputDto {
    return TimeslotInputDtoFromJSONTyped(json, false);
}

export function TimeslotInputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeslotInputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'week': json['week'],
        'year': json['year'],
        'active': json['active'],
    };
}

export function TimeslotInputDtoToJSON(value?: TimeslotInputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'week': value.week,
        'year': value.year,
        'active': value.active,
    };
}

