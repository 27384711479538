/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Item,
    ItemFromJSON,
    ItemFromJSONTyped,
    ItemToJSON,
} from './Item';

/**
 * 
 * @export
 * @interface ChangeOrderDto
 */
export interface ChangeOrderDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeOrderDto
     */
    token: string;
    /**
     * 
     * @type {Array<Item>}
     * @memberof ChangeOrderDto
     */
    items: Array<Item>;
    /**
     * 
     * @type {number}
     * @memberof ChangeOrderDto
     */
    deliveryFrequency: number;
}

export function ChangeOrderDtoFromJSON(json: any): ChangeOrderDto {
    return ChangeOrderDtoFromJSONTyped(json, false);
}

export function ChangeOrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeOrderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'items': ((json['items'] as Array<any>).map(ItemFromJSON)),
        'deliveryFrequency': json['deliveryFrequency'],
    };
}

export function ChangeOrderDtoToJSON(value?: ChangeOrderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'items': ((value.items as Array<any>).map(ItemToJSON)),
        'deliveryFrequency': value.deliveryFrequency,
    };
}

