/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShippingMethodOutputDto,
    ShippingMethodOutputDtoFromJSON,
    ShippingMethodOutputDtoFromJSONTyped,
    ShippingMethodOutputDtoToJSON,
} from './ShippingMethodOutputDto';

/**
 * 
 * @export
 * @interface ShippingOutputDto
 */
export interface ShippingOutputDto {
    /**
     * 
     * @type {string}
     * @memberof ShippingOutputDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOutputDto
     */
    street: string;
    /**
     * 
     * @type {Array<ShippingMethodOutputDto>}
     * @memberof ShippingOutputDto
     */
    method: Array<ShippingMethodOutputDto>;
}

export function ShippingOutputDtoFromJSON(json: any): ShippingOutputDto {
    return ShippingOutputDtoFromJSONTyped(json, false);
}

export function ShippingOutputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingOutputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': json['city'],
        'street': json['street'],
        'method': ((json['method'] as Array<any>).map(ShippingMethodOutputDtoFromJSON)),
    };
}

export function ShippingOutputDtoToJSON(value?: ShippingOutputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': value.city,
        'street': value.street,
        'method': ((value.method as Array<any>).map(ShippingMethodOutputDtoToJSON)),
    };
}

