/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExtraInitialInput,
    ExtraInitialInputFromJSON,
    ExtraInitialInputFromJSONTyped,
    ExtraInitialInputToJSON,
} from './ExtraInitialInput';

/**
 * 
 * @export
 * @interface InitialInput
 */
export interface InitialInput {
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    zipcode: string;
    /**
     * 
     * @type {boolean}
     * @memberof InitialInput
     */
    validZipcode: boolean;
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    deliveryMethod: string;
    /**
     * 
     * @type {boolean}
     * @memberof InitialInput
     */
    newsPaper: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InitialInput
     */
    agreement: boolean;
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    fruitBox: string;
    /**
     * 
     * @type {boolean}
     * @memberof InitialInput
     */
    sap: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InitialInput
     */
    honey: boolean;
    /**
     * 
     * @type {number}
     * @memberof InitialInput
     */
    sapCount: number;
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    deliveryFrequency: string;
    /**
     * 
     * @type {boolean}
     * @memberof InitialInput
     */
    gift: boolean;
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    giftMessage: string;
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    paymentMethod: string;
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    paymentFrequency: string;
    /**
     * 
     * @type {string}
     * @memberof InitialInput
     */
    note: string;
    /**
     * 
     * @type {Array<ExtraInitialInput>}
     * @memberof InitialInput
     */
    extra: Array<ExtraInitialInput>;
}

export function InitialInputFromJSON(json: any): InitialInput {
    return InitialInputFromJSONTyped(json, false);
}

export function InitialInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitialInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullName': json['fullName'],
        'email': json['email'],
        'phone': json['phone'],
        'zipcode': json['zipcode'],
        'validZipcode': json['validZipcode'],
        'number': json['number'],
        'street': json['street'],
        'city': json['city'],
        'deliveryMethod': json['deliveryMethod'],
        'newsPaper': json['newsPaper'],
        'agreement': json['agreement'],
        'fruitBox': json['fruitBox'],
        'sap': json['sap'],
        'honey': json['honey'],
        'sapCount': json['sapCount'],
        'deliveryFrequency': json['deliveryFrequency'],
        'gift': json['gift'],
        'giftMessage': json['giftMessage'],
        'paymentMethod': json['paymentMethod'],
        'paymentFrequency': json['paymentFrequency'],
        'note': json['note'],
        'extra': ((json['extra'] as Array<any>).map(ExtraInitialInputFromJSON)),
    };
}

export function InitialInputToJSON(value?: InitialInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullName': value.fullName,
        'email': value.email,
        'phone': value.phone,
        'zipcode': value.zipcode,
        'validZipcode': value.validZipcode,
        'number': value.number,
        'street': value.street,
        'city': value.city,
        'deliveryMethod': value.deliveryMethod,
        'newsPaper': value.newsPaper,
        'agreement': value.agreement,
        'fruitBox': value.fruitBox,
        'sap': value.sap,
        'honey': value.honey,
        'sapCount': value.sapCount,
        'deliveryFrequency': value.deliveryFrequency,
        'gift': value.gift,
        'giftMessage': value.giftMessage,
        'paymentMethod': value.paymentMethod,
        'paymentFrequency': value.paymentFrequency,
        'note': value.note,
        'extra': ((value.extra as Array<any>).map(ExtraInitialInputToJSON)),
    };
}

