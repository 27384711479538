/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimeslotOutputDto,
    TimeslotOutputDtoFromJSON,
    TimeslotOutputDtoFromJSONTyped,
    TimeslotOutputDtoToJSON,
} from './TimeslotOutputDto';

/**
 * 
 * @export
 * @interface TimeslotsOutputDto
 */
export interface TimeslotsOutputDto {
    /**
     * 
     * @type {Array<TimeslotOutputDto>}
     * @memberof TimeslotsOutputDto
     */
    slots: Array<TimeslotOutputDto>;
}

export function TimeslotsOutputDtoFromJSON(json: any): TimeslotsOutputDto {
    return TimeslotsOutputDtoFromJSONTyped(json, false);
}

export function TimeslotsOutputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeslotsOutputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slots': ((json['slots'] as Array<any>).map(TimeslotOutputDtoFromJSON)),
    };
}

export function TimeslotsOutputDtoToJSON(value?: TimeslotsOutputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slots': ((value.slots as Array<any>).map(TimeslotOutputDtoToJSON)),
    };
}

