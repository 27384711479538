/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeOperationResponse
 */
export interface ChangeOperationResponse {
    /**
     * 
     * @type {string}
     * @memberof ChangeOperationResponse
     */
    changeDate: string;
}

export function ChangeOperationResponseFromJSON(json: any): ChangeOperationResponse {
    return ChangeOperationResponseFromJSONTyped(json, false);
}

export function ChangeOperationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeOperationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'changeDate': json['changeDate'],
    };
}

export function ChangeOperationResponseToJSON(value?: ChangeOperationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'changeDate': value.changeDate,
    };
}

