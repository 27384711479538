/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeslotOutputDto
 */
export interface TimeslotOutputDto {
    /**
     * 
     * @type {number}
     * @memberof TimeslotOutputDto
     */
    week: number;
    /**
     * 
     * @type {number}
     * @memberof TimeslotOutputDto
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof TimeslotOutputDto
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof TimeslotOutputDto
     */
    active: boolean;
}

export function TimeslotOutputDtoFromJSON(json: any): TimeslotOutputDto {
    return TimeslotOutputDtoFromJSONTyped(json, false);
}

export function TimeslotOutputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeslotOutputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'week': json['week'],
        'year': json['year'],
        'description': json['description'],
        'active': json['active'],
    };
}

export function TimeslotOutputDtoToJSON(value?: TimeslotOutputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'week': value.week,
        'year': value.year,
        'description': value.description,
        'active': value.active,
    };
}

