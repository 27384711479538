/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimeslotInputDto,
    TimeslotInputDtoFromJSON,
    TimeslotInputDtoFromJSONTyped,
    TimeslotInputDtoToJSON,
} from './TimeslotInputDto';

/**
 * 
 * @export
 * @interface SingleTimeSlotUpdate
 */
export interface SingleTimeSlotUpdate {
    /**
     * 
     * @type {string}
     * @memberof SingleTimeSlotUpdate
     */
    token: string;
    /**
     * 
     * @type {TimeslotInputDto}
     * @memberof SingleTimeSlotUpdate
     */
    slot: TimeslotInputDto;
}

export function SingleTimeSlotUpdateFromJSON(json: any): SingleTimeSlotUpdate {
    return SingleTimeSlotUpdateFromJSONTyped(json, false);
}

export function SingleTimeSlotUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleTimeSlotUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'slot': TimeslotInputDtoFromJSON(json['slot']),
    };
}

export function SingleTimeSlotUpdateToJSON(value?: SingleTimeSlotUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'slot': TimeslotInputDtoToJSON(value.slot),
    };
}

