/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeShippingDto
 */
export interface ChangeShippingDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeShippingDto
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeShippingDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeShippingDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeShippingDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeShippingDto
     */
    zipcode: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeShippingDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeShippingDto
     */
    deliveryMethod: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeShippingDto
     */
    newsPaper: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChangeShippingDto
     */
    note?: string;
}

export function ChangeShippingDtoFromJSON(json: any): ChangeShippingDto {
    return ChangeShippingDtoFromJSONTyped(json, false);
}

export function ChangeShippingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeShippingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'name': json['name'],
        'email': json['email'],
        'phone': json['phone'],
        'zipcode': json['zipcode'],
        'number': json['number'],
        'deliveryMethod': json['deliveryMethod'],
        'newsPaper': json['newsPaper'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function ChangeShippingDtoToJSON(value?: ChangeShippingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'zipcode': value.zipcode,
        'number': value.number,
        'deliveryMethod': value.deliveryMethod,
        'newsPaper': value.newsPaper,
        'note': value.note,
    };
}

