/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Item,
    ItemFromJSON,
    ItemFromJSONTyped,
    ItemToJSON,
} from './Item';

/**
 * 
 * @export
 * @interface OrderInputDto
 */
export interface OrderInputDto {
    /**
     * 
     * @type {number}
     * @memberof OrderInputDto
     */
    deliveryFrequency: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInputDto
     */
    gift: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderInputDto
     */
    category: OrderInputDtoCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderInputDto
     */
    giftMessage: string;
    /**
     * 
     * @type {string}
     * @memberof OrderInputDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrderInputDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof OrderInputDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof OrderInputDto
     */
    zipcode: string;
    /**
     * 
     * @type {string}
     * @memberof OrderInputDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof OrderInputDto
     */
    deliveryMethod: string;
    /**
     * 
     * @type {string}
     * @memberof OrderInputDto
     */
    issuer: string;
    /**
     * 
     * @type {number}
     * @memberof OrderInputDto
     */
    paymentFrequency: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInputDto
     */
    newsPaper: boolean;
    /**
     *
     * @type {boolean}
     * @memberof OrderInputDto
     */
    ebook: boolean;
    /**
     * 
     * @type {Array<Item>}
     * @memberof OrderInputDto
     */
    items: Array<Item>;
    /**
     * 
     * @type {string}
     * @memberof OrderInputDto
     */
    changeToken?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderInputDto
     */
    subscriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderInputDto
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderInputDto
     */
    startDate: string;
}

/**
* @export
* @enum {string}
*/
export enum OrderInputDtoCategoryEnum {
    NewCustomers = 'newCustomers',
    CurrentCustomers = 'currentCustomers',
    NewCustomersV2 = 'newCustomersV2',
    NewCustomersV3 = 'newCustomersV3',
    NewCustomersV4 = 'newCustomersV4',
    NewCustomersV5 = 'newCustomersV5'
}

export function OrderInputDtoFromJSON(json: any): OrderInputDto {
    return OrderInputDtoFromJSONTyped(json, false);
}

export function OrderInputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderInputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryFrequency': json['deliveryFrequency'],
        'gift': json['gift'],
        'category': json['category'],
        'giftMessage': json['giftMessage'],
        'name': json['name'],
        'email': json['email'],
        'phone': json['phone'],
        'zipcode': json['zipcode'],
        'number': json['number'],
        'deliveryMethod': json['deliveryMethod'],
        'issuer': json['issuer'],
        'paymentFrequency': json['paymentFrequency'],
        'newsPaper': json['newsPaper'],
        'items': ((json['items'] as Array<any>).map(ItemFromJSON)),
        'changeToken': !exists(json, 'changeToken') ? undefined : json['changeToken'],
        'subscriptionId': !exists(json, 'subscriptionId') ? undefined : json['subscriptionId'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'startDate': json['startDate'],
        'ebook': json['ebook']
    };
}

export function OrderInputDtoToJSON(value?: OrderInputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryFrequency': value.deliveryFrequency,
        'gift': value.gift,
        'category': value.category,
        'giftMessage': value.giftMessage,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'zipcode': value.zipcode,
        'number': value.number,
        'deliveryMethod': value.deliveryMethod,
        'issuer': value.issuer,
        'paymentFrequency': value.paymentFrequency,
        'newsPaper': value.newsPaper,
        'items': ((value.items as Array<any>).map(ItemToJSON)),
        'changeToken': value.changeToken,
        'subscriptionId': value.subscriptionId,
        'note': value.note,
        'startDate': value.startDate,
        'ebook': value.ebook
    };
}

