/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PriceOutputDto
 */
export interface PriceOutputDto {
    /**
     * 
     * @type {number}
     * @memberof PriceOutputDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof PriceOutputDto
     */
    bundleAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PriceOutputDto
     */
    additionAmount: number;
    /**
     * 
     * @type {string}
     * @memberof PriceOutputDto
     */
    calculationMethod: PriceOutputDtoCalculationMethodEnum;
}

/**
* @export
* @enum {string}
*/
export enum PriceOutputDtoCalculationMethodEnum {
    Default = 'default',
    LitreCalculator = 'litreCalculator'
}

export function PriceOutputDtoFromJSON(json: any): PriceOutputDto {
    return PriceOutputDtoFromJSONTyped(json, false);
}

export function PriceOutputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceOutputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'bundleAmount': json['bundleAmount'],
        'additionAmount': json['additionAmount'],
        'calculationMethod': json['calculationMethod'],
    };
}

export function PriceOutputDtoToJSON(value?: PriceOutputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'bundleAmount': value.bundleAmount,
        'additionAmount': value.additionAmount,
        'calculationMethod': value.calculationMethod,
    };
}

