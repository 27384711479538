/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExtraInitialInput,
    ExtraInitialInputFromJSON,
    ExtraInitialInputFromJSONTyped,
    ExtraInitialInputToJSON,
} from './ExtraInitialInput';
import {
    Item,
    ItemFromJSON,
    ItemFromJSONTyped,
    ItemToJSON,
} from './Item';

/**
 * 
 * @export
 * @interface SubscriptionChangeOutputDto
 */
export interface SubscriptionChangeOutputDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangeOutputDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangeOutputDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangeOutputDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangeOutputDto
     */
    zipcode: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangeOutputDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangeOutputDto
     */
    deliveryMethod: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionChangeOutputDto
     */
    even: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangeOutputDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangeOutputDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangeOutputDto
     */
    fruitbox: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionChangeOutputDto
     */
    sap: boolean;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionChangeOutputDto
     */
    sapCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionChangeOutputDto
     */
    newsPaper: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangeOutputDto
     */
    note: string;
    /**
     * 
     * @type {Array<Item>}
     * @memberof SubscriptionChangeOutputDto
     */
    items: Array<Item>;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionChangeOutputDto
     */
    deliveryFrequency: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangeOutputDto
     */
    category: SubscriptionChangeOutputDtoCategoryEnum;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionChangeOutputDto
     */
    paymentFrequency: number;
    /**
     *
     * @type {string}
     * @memberof SubscriptionChangeOutputDto
     */
    nextDeliveryDay: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangeOutputDto
     */
    changeDate: string;
    /**
     * 
     * @type {Array<ExtraInitialInput>}
     * @memberof SubscriptionChangeOutputDto
     */
    extra: Array<ExtraInitialInput>;
}

/**
* @export
* @enum {string}
*/
export enum SubscriptionChangeOutputDtoCategoryEnum {
    NewCustomers = 'newCustomers',
    CurrentCustomers = 'currentCustomers',
    NewCustomersV2 = 'newCustomersV2',
    NewCustomersV3 = 'newCustomersV3',
    NewCustomersV4 = 'newCustomersV4',
    NewCustomersV5 = 'newCustomersV5'
}

export function SubscriptionChangeOutputDtoFromJSON(json: any): SubscriptionChangeOutputDto {
    return SubscriptionChangeOutputDtoFromJSONTyped(json, false);
}

export function SubscriptionChangeOutputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionChangeOutputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'email': json['email'],
        'phone': json['phone'],
        'zipcode': json['zipcode'],
        'number': json['number'],
        'deliveryMethod': json['deliveryMethod'],
        'even': json['even'],
        'street': json['street'],
        'city': json['city'],
        'fruitbox': json['fruitbox'],
        'sap': json['sap'],
        'sapCount': json['sapCount'],
        'newsPaper': json['newsPaper'],
        'note': json['note'],
        'items': ((json['items'] as Array<any>).map(ItemFromJSON)),
        'deliveryFrequency': json['deliveryFrequency'],
        'category': json['category'],
        'paymentFrequency': json['paymentFrequency'],
        'changeDate': json['changeDate'],
        'nextDeliveryDay': json['nextDeliveryDay'],
        'extra': ((json['extra'] as Array<any>).map(ExtraInitialInputFromJSON)),
    };
}

export function SubscriptionChangeOutputDtoToJSON(value?: SubscriptionChangeOutputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'zipcode': value.zipcode,
        'number': value.number,
        'deliveryMethod': value.deliveryMethod,
        'even': value.even,
        'street': value.street,
        'city': value.city,
        'fruitbox': value.fruitbox,
        'sap': value.sap,
        'sapCount': value.sapCount,
        'newsPaper': value.newsPaper,
        'note': value.note,
        'items': ((value.items as Array<any>).map(ItemToJSON)),
        'deliveryFrequency': value.deliveryFrequency,
        'category': value.category,
        'paymentFrequency': value.paymentFrequency,
        'changeDate': value.changeDate,
        'nextDeliveryDay': value.nextDeliveryDay,
        'extra': ((value.extra as Array<any>).map(ExtraInitialInputToJSON)),
    };
}

