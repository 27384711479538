/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InitialInput,
    InitialInputFromJSON,
    InitialInputFromJSONTyped,
    InitialInputToJSON,
} from './InitialInput';
import {
    Item,
    ItemFromJSON,
    ItemFromJSONTyped,
    ItemToJSON,
} from './Item';

/**
 * 
 * @export
 * @interface OnboardingOutputDto
 */
export interface OnboardingOutputDto {
    /**
     * 
     * @type {InitialInput}
     * @memberof OnboardingOutputDto
     */
    initialInput: InitialInput;
    /**
     * 
     * @type {string}
     * @memberof OnboardingOutputDto
     */
    shippingMethod: string;
    /**
     * 
     * @type {Array<Item>}
     * @memberof OnboardingOutputDto
     */
    shoppingCart: Array<Item>;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingOutputDto
     */
    evenWeeks: boolean;
}

export function OnboardingOutputDtoFromJSON(json: any): OnboardingOutputDto {
    return OnboardingOutputDtoFromJSONTyped(json, false);
}

export function OnboardingOutputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingOutputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initialInput': InitialInputFromJSON(json['initialInput']),
        'shippingMethod': json['shippingMethod'],
        'shoppingCart': ((json['shoppingCart'] as Array<any>).map(ItemFromJSON)),
        'evenWeeks': json['evenWeeks'],
    };
}

export function OnboardingOutputDtoToJSON(value?: OnboardingOutputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initialInput': InitialInputToJSON(value.initialInput),
        'shippingMethod': value.shippingMethod,
        'shoppingCart': ((value.shoppingCart as Array<any>).map(ItemToJSON)),
        'evenWeeks': value.evenWeeks,
    };
}

