import { FunctionComponent } from "react";
import { SpinnerIcon } from "../atoms/icons/spinnerIcon";

interface FormSectionProps {
  disableMinHeight?: boolean;
  loading: boolean;
}

export const FormSection: FunctionComponent<FormSectionProps> = ({
  children,
  disableMinHeight = false,
  loading = false,
}) => (
  <main
    className={`w-full ${
      disableMinHeight ? "" : "min-h-[60vh]"
    } h-1/2 bg-peach rounded-24 md:rounded-44 block pl-24 py-46 mt-8 md:mt-16 md:px-114 md:py-66 font-medium mb-10 relative`}
  >
    {loading ? (
      <div
        className={
          "absolute w-full h-full top-0 bottom-0 right-0 bg-peach bg-opacity-50 rounded-24 flex items-center justify-center z-30"
        }
      >
        <SpinnerIcon height={"h-12"} width={"h-12"} />
      </div>
    ) : null}
    {children}
  </main>
);
