export const Logo = () => (
    <svg width="151" height="46" viewBox="0 0 151 46" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_51_234)">
            <path d="M29.1912 23.1828L37.9772 28.1133C36.4433 35.7462 31.0721 45.9246 24.0674 45.8909C23.3703 45.8476 22.6796 45.7402 22.0034 45.5702C21.6083 45.4819 21.2565 45.2638 21.0028 44.9527C20.7506 44.6415 20.6124 44.2534 20.6108 43.854V27.8262L28.8716 23.1828C28.9198 23.154 28.9744 23.1379 29.0322 23.1379C29.09 23.1379 29.1446 23.154 29.1928 23.1828H29.1912Z" fill="url(#paint0_linear_51_234)"/>
            <path d="M38.4206 24.2045V24.3184L30.9549 20.1226C30.3686 19.7938 29.7053 19.6222 29.0307 19.6222C28.3561 19.6222 27.6927 19.7954 27.1064 20.1226L12.2008 28.511C11.9904 28.6217 11.8041 28.7725 11.6531 28.9553C11.5021 29.1381 11.3897 29.3483 11.3238 29.5744C11.2579 29.8006 11.2371 30.0379 11.266 30.2721C11.2933 30.5063 11.3688 30.7324 11.4876 30.9361C11.6065 31.1414 11.7655 31.3195 11.955 31.4622C12.1446 31.605 12.3614 31.7092 12.5927 31.7669C12.824 31.8247 13.0649 31.8375 13.301 31.8022C13.5372 31.7669 13.7636 31.6851 13.9676 31.5616L17.0499 29.8294V43.9903C17.0548 44.3977 16.9134 44.7955 16.6516 45.1114C16.3898 45.4274 16.0236 45.6407 15.6172 45.7161C15.1193 45.8011 14.6149 45.8428 14.109 45.8396C5.84662 45.7963 -0.0449623 31.4975 1.1587e-05 24.0008C1.1587e-05 22.5782 0.195969 18.9389 1.81664 15.7985C3.53528 12.4575 6.37667 10.6997 10.0388 10.7173C13.8182 10.7333 16.9006 11.9122 17.5334 12.095C18.7702 12.4511 20.0873 12.4271 21.3112 12.0261C23.637 11.2466 26.0736 10.8408 28.5295 10.8232C32.1901 10.8392 35.017 12.6163 36.7067 15.9909C38.2712 19.1394 38.4222 22.7883 38.4222 24.2045H38.4206Z" fill="url(#paint1_linear_51_234)"/>
            <path d="M27.6637 1.32806C27.9335 3.53343 27.3055 5.75484 25.9177 7.5047C24.5284 9.25296 22.4933 10.3869 20.2574 10.6548C19.8912 10.6981 19.5218 10.7189 19.154 10.7157C19.0271 10.2746 18.9355 9.82555 18.8793 9.37164C18.6095 7.16788 19.2391 4.94968 20.6269 3.20463C22.0146 1.45798 24.0481 0.327223 26.2823 0.0609744C26.6502 0.0144612 27.0212 -0.00478567 27.3938 0.00323386C27.5191 0.437892 27.6107 0.882174 27.6669 1.32966L27.6637 1.32806Z" fill="#1EA96A"/>
            <path d="M46.8022 26.0234V11.1407H57.5542V14.9677H51.2739V16.902H56.7463V20.3039H51.2739V26.0234H46.8022Z" fill="black"/>
            <path d="M63.176 17.2003C63.5374 14.9259 65.7315 14.6068 66.3274 14.6068C66.6679 14.6068 66.5828 14.6068 66.9667 14.6485V18.9004C66.4125 18.7512 65.8166 18.7512 65.5612 18.7512C63.7735 18.7512 63.3463 19.9846 63.3463 21.1539V26.0217H58.9597V14.8393H63.176V17.1987V17.2003Z" fill="black"/>
            <path d="M72.5017 20.9631C72.5017 21.834 72.6928 22.8557 73.822 22.8557C74.9511 22.8557 75.1214 21.8356 75.1214 20.9631V14.841H79.5079V26.0234H75.1214V24.6408C74.5046 25.6401 73.5248 26.256 72.0134 26.256C68.5423 26.256 68.1167 23.215 68.1167 21.3881V14.8394H72.5033V20.9615L72.5017 20.9631Z" fill="black"/>
            <path d="M83.7853 10.2474C85.4461 10.2474 86.2331 11.0974 86.2331 12.2025C86.2331 13.3076 85.361 14.1577 83.7853 14.1577C82.2096 14.1577 81.3374 13.3076 81.3374 12.2025C81.3374 11.0974 82.1244 10.2474 83.7853 10.2474ZM85.9777 14.8394H81.5912V26.0218H85.9777V14.8394Z" fill="black"/>
            <path d="M93.196 26.256C91.1947 26.256 88.938 25.4267 88.938 22.1532V18.0071H87.4056V14.8394H89.407L89.9177 12.0967H93.2185V14.8394H95.88V18.0071H93.2185V22.1949C93.2185 22.6841 93.453 22.8749 93.7502 22.8749C94.1967 22.8749 94.3461 22.429 94.3461 22.0249C94.3461 21.5357 94.2401 21.1106 94.1549 21.0048H96.9433C97.1778 21.3656 97.3047 21.8757 97.3272 22.344C97.3272 25.4684 95.1122 26.256 93.196 26.256Z" fill="black"/>
            <path d="M102.862 26.0234H98.4757V10.6531H102.862V16.2444C103.458 15.2243 104.608 14.6068 106.055 14.6068C109.037 14.6068 111.314 17.0511 111.314 20.4321C111.314 23.8132 109.037 26.2575 106.055 26.2575C104.608 26.2575 103.458 25.6416 102.862 24.6424V26.025V26.0234ZM104.864 17.9445C103.587 17.9445 102.798 19.0287 102.798 20.4321C102.798 21.8356 103.585 22.9198 104.864 22.9198C106.142 22.9198 106.929 21.8356 106.929 20.4321C106.929 19.0287 106.142 17.9445 104.864 17.9445Z" fill="black"/>
            <path d="M118.724 26.2559C114.891 26.2559 112.252 23.6624 112.252 20.4305C112.252 17.1987 114.893 14.6052 118.724 14.6052C122.554 14.6052 125.197 17.1987 125.197 20.4305C125.197 23.6624 122.556 26.2559 118.724 26.2559ZM118.724 22.854C120.108 22.854 121.13 21.8548 121.13 20.4305C121.13 19.0063 120.108 18.007 118.724 18.007C117.339 18.007 116.318 19.0271 116.318 20.4305C116.318 21.834 117.318 22.854 118.724 22.854Z" fill="black"/>
            <path d="M126.112 20.4738C126.112 17.412 128.327 14.6068 132.372 14.6068C135.905 14.6068 138.546 16.7319 138.546 20.3038C138.546 20.7289 138.502 21.0897 138.44 21.3031H130.2C130.2 21.919 131.18 22.9823 133.075 22.9823C133.82 22.9823 134.97 22.7915 136.098 21.8981L137.908 24.3216C136.524 25.725 134.545 26.2559 132.693 26.2559C128.562 26.2559 126.114 23.6833 126.114 20.4738H126.112ZM130.2 19.2837H134.587C134.522 18.1995 133.48 17.6686 132.394 17.6686C131.308 17.6686 130.264 18.1995 130.202 19.2837H130.2Z" fill="black"/>
            <path d="M144.211 17.2003C144.572 14.9259 146.766 14.6068 147.362 14.6068C147.703 14.6068 147.618 14.6068 148.002 14.6485V18.9004C147.447 18.7512 146.852 18.7512 146.596 18.7512C144.808 18.7512 144.381 19.9846 144.381 21.1539V26.0217H139.995V14.8393H144.209V17.1987L144.211 17.2003Z" fill="black"/>
            <path d="M54.4463 44.3849C53.8504 45.3841 52.7003 46 51.2531 46C48.272 46 45.9944 43.5557 45.9944 40.1746C45.9944 36.7936 48.272 34.3492 51.2531 34.3492C52.7003 34.3492 53.8504 34.9651 54.4463 35.9868V34.5834H58.8328V45.7658H54.4463V44.3833V44.3849ZM52.4449 42.6623C53.7219 42.6623 54.5105 41.578 54.5105 40.1746C54.5105 38.7712 53.7235 37.687 52.4449 37.687C51.1664 37.687 50.3793 38.7712 50.3793 40.1746C50.3793 41.578 51.1664 42.6623 52.4449 42.6623Z" fill="black"/>
            <path d="M68.817 44.3849C68.2211 45.3841 67.071 46 65.6238 46C62.6427 46 60.3651 43.5557 60.3651 40.1746C60.3651 36.7936 62.6427 34.3492 65.6238 34.3492C67.071 34.3492 68.2211 34.9651 68.817 35.9868V34.5834H73.2036V45.7658H68.817V44.3833V44.3849ZM66.8156 42.6623C68.0926 42.6623 68.8812 41.578 68.8812 40.1746C68.8812 38.7712 68.0942 37.687 66.8156 37.687C65.5371 37.687 64.7501 38.7712 64.7501 40.1746C64.7501 41.578 65.5371 42.6623 66.8156 42.6623Z" fill="black"/>
            <path d="M82.2947 39.6437C82.2947 38.7728 82.1036 37.7511 80.9744 37.7511C79.8453 37.7511 79.675 38.7712 79.675 39.6437V45.7658H75.2885V34.5834H79.675V35.966C80.2918 34.9667 81.2716 34.3508 82.783 34.3508C86.2541 34.3508 86.6797 37.3902 86.6797 39.2187V45.7674H82.2931V39.6453L82.2947 39.6437Z" fill="black"/>
            <path d="M100.371 39.6437C100.371 38.7728 100.18 37.7511 99.0507 37.7511C97.9216 37.7511 97.7513 38.7712 97.7513 39.6437V45.7659H93.3647V30.3956H97.7513V35.966C98.3681 34.9668 99.3479 34.3509 100.859 34.3509C104.33 34.3509 104.756 37.3903 104.756 39.2187V45.7675H100.369V39.6454L100.371 39.6437Z" fill="black"/>
            <path d="M110.974 40.7071C110.974 41.5796 111.165 42.5997 112.294 42.5997C113.423 42.5997 113.593 41.5796 113.593 40.7071V34.585H117.98V45.7674H113.593V44.3848C112.977 45.3841 111.997 46 110.485 46C107.014 46 106.589 42.959 106.589 41.1321V34.5834H110.975V40.7055L110.974 40.7071Z" fill="black"/>
            <path d="M122.259 29.9915C123.92 29.9915 124.707 30.8415 124.707 31.9466C124.707 33.0517 123.834 33.9018 122.259 33.9018C120.683 33.9018 119.811 33.0517 119.811 31.9466C119.811 30.8415 120.598 29.9915 122.259 29.9915ZM124.451 34.5834H120.065V45.7659H124.451V34.5834Z" fill="black"/>
            <path d="M129.008 41.6839C129.222 42.7265 129.945 43.044 130.839 43.044C131.478 43.044 131.883 42.8532 131.883 42.4907C131.883 42.2774 131.798 42.1298 131.138 41.8956L129.861 41.4273C129.244 41.1931 126.369 40.4714 126.369 38.0254C126.369 35.7928 128.35 34.3477 130.924 34.3477C132.926 34.3477 134.501 35.1127 135.353 37.1962L132.18 38.0896C131.882 37.4095 131.393 37.1962 130.881 37.1962C130.412 37.1962 130.177 37.4512 130.177 37.7271C130.177 38.003 130.412 38.1313 130.923 38.3013L132.158 38.8113C134.585 39.6614 135.671 40.8515 135.714 42.3832C135.714 44.8917 133.436 45.9968 130.839 45.9968C128.051 45.9968 126.197 44.8484 125.815 42.4041L129.008 41.6807V41.6839Z" fill="black"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_51_234" x1="-5.58289e-05" y1="34.5144" x2="38.4205" y2="34.5144" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1EA96A"/>
                <stop offset="1" stopColor="#1EA96A"/>
            </linearGradient>
            <linearGradient id="paint1_linear_51_234" x1="1.1587e-05" y1="28.2785" x2="38.4206" y2="28.2785" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1EA96A"/>
                <stop offset="1" stopColor="#1EA96A"/>
            </linearGradient>
            <clipPath id="clip0_51_234">
                <rect width="151" height="46" fill="white"/>
            </clipPath>
        </defs>
    </svg>

)