/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductOutputDto,
    ProductOutputDtoFromJSON,
    ProductOutputDtoFromJSONTyped,
    ProductOutputDtoToJSON,
} from './ProductOutputDto';

/**
 * 
 * @export
 * @interface AllProductOutputDto
 */
export interface AllProductOutputDto {
    /**
     * 
     * @type {Array<ProductOutputDto>}
     * @memberof AllProductOutputDto
     */
    fruitBoxes: Array<ProductOutputDto>;
    /**
     * 
     * @type {Array<ProductOutputDto>}
     * @memberof AllProductOutputDto
     */
    extras: Array<ProductOutputDto>;
    /**
     * 
     * @type {Array<ProductOutputDto>}
     * @memberof AllProductOutputDto
     */
    gift: Array<ProductOutputDto>;
}

export function AllProductOutputDtoFromJSON(json: any): AllProductOutputDto {
    return AllProductOutputDtoFromJSONTyped(json, false);
}

export function AllProductOutputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllProductOutputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fruitBoxes': ((json['fruitBoxes'] as Array<any>).map(ProductOutputDtoFromJSON)),
        'extras': ((json['extras'] as Array<any>).map(ProductOutputDtoFromJSON)),
        'gift': ((json['gift'] as Array<any>).map(ProductOutputDtoFromJSON)),
    };
}

export function AllProductOutputDtoToJSON(value?: AllProductOutputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fruitBoxes': ((value.fruitBoxes as Array<any>).map(ProductOutputDtoToJSON)),
        'extras': ((value.extras as Array<any>).map(ProductOutputDtoToJSON)),
        'gift': ((value.gift as Array<any>).map(ProductOutputDtoToJSON)),
    };
}

