/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AllProductOutputDto,
    AllProductOutputDtoFromJSON,
    AllProductOutputDtoToJSON,
    BulkTimeSlotOutputDto,
    BulkTimeSlotOutputDtoFromJSON,
    BulkTimeSlotOutputDtoToJSON,
    BulkTimeSlotUpdate,
    BulkTimeSlotUpdateFromJSON,
    BulkTimeSlotUpdateToJSON,
    CancelDto,
    CancelDtoFromJSON,
    CancelDtoToJSON,
    ChangeEmail,
    ChangeEmailFromJSON,
    ChangeEmailToJSON,
    ChangeOperationResponse,
    ChangeOperationResponseFromJSON,
    ChangeOperationResponseToJSON,
    ChangeOrderDto,
    ChangeOrderDtoFromJSON,
    ChangeOrderDtoToJSON,
    ChangePaymentFrequencyDto,
    ChangePaymentFrequencyDtoFromJSON,
    ChangePaymentFrequencyDtoToJSON,
    ChangeShippingDto,
    ChangeShippingDtoFromJSON,
    ChangeShippingDtoToJSON,
    CustomerEmailInputDto,
    CustomerEmailInputDtoFromJSON,
    CustomerEmailInputDtoToJSON,
    CustomerEmailOutputDto,
    CustomerEmailOutputDtoFromJSON,
    CustomerEmailOutputDtoToJSON,
    OnboardingOutputDto,
    OnboardingOutputDtoFromJSON,
    OnboardingOutputDtoToJSON,
    OrderInputDto,
    OrderInputDtoFromJSON,
    OrderInputDtoToJSON,
    OrderOutputDto,
    OrderOutputDtoFromJSON,
    OrderOutputDtoToJSON,
    ShippingOutputDto,
    ShippingOutputDtoFromJSON,
    ShippingOutputDtoToJSON,
    SingleTimeSlotUpdate,
    SingleTimeSlotUpdateFromJSON,
    SingleTimeSlotUpdateToJSON,
    SubscriptionChangeOutputDto,
    SubscriptionChangeOutputDtoFromJSON,
    SubscriptionChangeOutputDtoToJSON,
    TimeslotsOutputDto,
    TimeslotsOutputDtoFromJSON,
    TimeslotsOutputDtoToJSON,
} from '../models';

export interface ChangeControllerCancelSubscriptionRequest {
    cancelDto: CancelDto;
}

export interface ChangeControllerChangeEmailRequest {
    changeEmail: ChangeEmail;
}

export interface ChangeControllerChangeSubscriptionOrderRequest {
    changeOrderDto: ChangeOrderDto;
}

export interface ChangeControllerChangeSubscriptionPaymentFrequencyRequest {
    changePaymentFrequencyDto: ChangePaymentFrequencyDto;
}

export interface ChangeControllerChangeSubscriptionShippingRequest {
    changeShippingDto: ChangeShippingDto;
}

export interface ChangeControllerGetSubscriptionRequest {
    token: string;
}

export interface OnboardingControllerGetSubscriptionRequest {
    id: string;
    token: string;
}

export interface OrderControllerNewOrderRequest {
    orderInputDto: OrderInputDto;
}

export interface PauseControllerGetTimeSlotsRequest {
    token: string;
}

export interface PauseControllerUpdateBulkTimeSlotRequest {
    bulkTimeSlotUpdate: BulkTimeSlotUpdate;
}

export interface PauseControllerUpdateSingleTimeSlotRequest {
    singleTimeSlotUpdate: SingleTimeSlotUpdate;
}

export interface ProductControllerGetProductRequest {
    category: ProductControllerGetProductCategoryEnum;
}

export interface ShippingMethodsControllerGetShippingMethodsRequest {
    zipcode: string;
    number: string;
    orderType: ShippingMethodsControllerGetShippingMethodsOrderTypeEnum;
    even: ShippingMethodsControllerGetShippingMethodsEvenEnum;
    changeFlow: ShippingMethodsControllerGetShippingMethodsChangeFlowEnum;
    twoWeekly: ShippingMethodsControllerGetShippingMethodsTwoWeeklyEnum;
}

export interface SubscriptionControllerCheckEmailHasNoSubscriptionRequest {
    customerEmailInputDto: CustomerEmailInputDto;
}

export interface SubscriptionControllerUpdateOrderRequest {
    body: object;
}

export interface SubscriptionControllerUpdateShippingRequest {
    body: object;
}

/**
 * 
 */
export class BackendApi extends runtime.BaseAPI {

    /**
     */
    async changeControllerCancelSubscriptionRaw(requestParameters: ChangeControllerCancelSubscriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChangeOperationResponse>> {
        if (requestParameters.cancelDto === null || requestParameters.cancelDto === undefined) {
            throw new runtime.RequiredError('cancelDto','Required parameter requestParameters.cancelDto was null or undefined when calling changeControllerCancelSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/change/subscription/cancel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelDtoToJSON(requestParameters.cancelDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeOperationResponseFromJSON(jsonValue));
    }

    /**
     */
    async changeControllerCancelSubscription(requestParameters: ChangeControllerCancelSubscriptionRequest, initOverrides?: RequestInit): Promise<ChangeOperationResponse> {
        const response = await this.changeControllerCancelSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeControllerChangeEmailRaw(requestParameters: ChangeControllerChangeEmailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.changeEmail === null || requestParameters.changeEmail === undefined) {
            throw new runtime.RequiredError('changeEmail','Required parameter requestParameters.changeEmail was null or undefined when calling changeControllerChangeEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/change/subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeEmailToJSON(requestParameters.changeEmail),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeControllerChangeEmail(requestParameters: ChangeControllerChangeEmailRequest, initOverrides?: RequestInit): Promise<void> {
        await this.changeControllerChangeEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async changeControllerChangeSubscriptionOrderRaw(requestParameters: ChangeControllerChangeSubscriptionOrderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChangeOperationResponse>> {
        if (requestParameters.changeOrderDto === null || requestParameters.changeOrderDto === undefined) {
            throw new runtime.RequiredError('changeOrderDto','Required parameter requestParameters.changeOrderDto was null or undefined when calling changeControllerChangeSubscriptionOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/change/subscription/order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeOrderDtoToJSON(requestParameters.changeOrderDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeOperationResponseFromJSON(jsonValue));
    }

    /**
     */
    async changeControllerChangeSubscriptionOrder(requestParameters: ChangeControllerChangeSubscriptionOrderRequest, initOverrides?: RequestInit): Promise<ChangeOperationResponse> {
        const response = await this.changeControllerChangeSubscriptionOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeControllerChangeSubscriptionPaymentFrequencyRaw(requestParameters: ChangeControllerChangeSubscriptionPaymentFrequencyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChangeOperationResponse>> {
        if (requestParameters.changePaymentFrequencyDto === null || requestParameters.changePaymentFrequencyDto === undefined) {
            throw new runtime.RequiredError('changePaymentFrequencyDto','Required parameter requestParameters.changePaymentFrequencyDto was null or undefined when calling changeControllerChangeSubscriptionPaymentFrequency.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/change/subscription/payment/frequency`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePaymentFrequencyDtoToJSON(requestParameters.changePaymentFrequencyDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeOperationResponseFromJSON(jsonValue));
    }

    /**
     */
    async changeControllerChangeSubscriptionPaymentFrequency(requestParameters: ChangeControllerChangeSubscriptionPaymentFrequencyRequest, initOverrides?: RequestInit): Promise<ChangeOperationResponse> {
        const response = await this.changeControllerChangeSubscriptionPaymentFrequencyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeControllerChangeSubscriptionShippingRaw(requestParameters: ChangeControllerChangeSubscriptionShippingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChangeOperationResponse>> {
        if (requestParameters.changeShippingDto === null || requestParameters.changeShippingDto === undefined) {
            throw new runtime.RequiredError('changeShippingDto','Required parameter requestParameters.changeShippingDto was null or undefined when calling changeControllerChangeSubscriptionShipping.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/change/subscription/shipping`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeShippingDtoToJSON(requestParameters.changeShippingDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeOperationResponseFromJSON(jsonValue));
    }

    /**
     */
    async changeControllerChangeSubscriptionShipping(requestParameters: ChangeControllerChangeSubscriptionShippingRequest, initOverrides?: RequestInit): Promise<ChangeOperationResponse> {
        const response = await this.changeControllerChangeSubscriptionShippingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeControllerGetSubscriptionRaw(requestParameters: ChangeControllerGetSubscriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubscriptionChangeOutputDto>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling changeControllerGetSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/change/subscription/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionChangeOutputDtoFromJSON(jsonValue));
    }

    /**
     */
    async changeControllerGetSubscription(requestParameters: ChangeControllerGetSubscriptionRequest, initOverrides?: RequestInit): Promise<SubscriptionChangeOutputDto> {
        const response = await this.changeControllerGetSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async onboardingControllerChangeUrlsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/onboarding/changeUrls`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async onboardingControllerChangeUrls(initOverrides?: RequestInit): Promise<void> {
        await this.onboardingControllerChangeUrlsRaw(initOverrides);
    }

    /**
     */
    async onboardingControllerCustomerImportRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/onboarding`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async onboardingControllerCustomerImport(initOverrides?: RequestInit): Promise<void> {
        await this.onboardingControllerCustomerImportRaw(initOverrides);
    }

    /**
     */
    async onboardingControllerGetSubscriptionRaw(requestParameters: OnboardingControllerGetSubscriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnboardingOutputDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling onboardingControllerGetSubscription.');
        }

        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling onboardingControllerGetSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/onboarding/{token}/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingOutputDtoFromJSON(jsonValue));
    }

    /**
     */
    async onboardingControllerGetSubscription(requestParameters: OnboardingControllerGetSubscriptionRequest, initOverrides?: RequestInit): Promise<OnboardingOutputDto> {
        const response = await this.onboardingControllerGetSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerIsEvenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/order/even/{date}`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderControllerIsEven(initOverrides?: RequestInit): Promise<void> {
        await this.orderControllerIsEvenRaw(initOverrides);
    }

    /**
     */
    async orderControllerNewOrderRaw(requestParameters: OrderControllerNewOrderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderOutputDto>> {
        if (requestParameters.orderInputDto === null || requestParameters.orderInputDto === undefined) {
            throw new runtime.RequiredError('orderInputDto','Required parameter requestParameters.orderInputDto was null or undefined when calling orderControllerNewOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderInputDtoToJSON(requestParameters.orderInputDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderOutputDtoFromJSON(jsonValue));
    }

    /**
     */
    async orderControllerNewOrder(requestParameters: OrderControllerNewOrderRequest, initOverrides?: RequestInit): Promise<OrderOutputDto> {
        const response = await this.orderControllerNewOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerTestRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderControllerTest(initOverrides?: RequestInit): Promise<void> {
        await this.orderControllerTestRaw(initOverrides);
    }

    /**
     */
    async pauseControllerGetTimeSlotsRaw(requestParameters: PauseControllerGetTimeSlotsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TimeslotsOutputDto>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling pauseControllerGetTimeSlots.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pause/timeslots/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimeslotsOutputDtoFromJSON(jsonValue));
    }

    /**
     */
    async pauseControllerGetTimeSlots(requestParameters: PauseControllerGetTimeSlotsRequest, initOverrides?: RequestInit): Promise<TimeslotsOutputDto> {
        const response = await this.pauseControllerGetTimeSlotsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async pauseControllerUpdateBulkTimeSlotRaw(requestParameters: PauseControllerUpdateBulkTimeSlotRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BulkTimeSlotOutputDto>> {
        if (requestParameters.bulkTimeSlotUpdate === null || requestParameters.bulkTimeSlotUpdate === undefined) {
            throw new runtime.RequiredError('bulkTimeSlotUpdate','Required parameter requestParameters.bulkTimeSlotUpdate was null or undefined when calling pauseControllerUpdateBulkTimeSlot.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pause/timeslots/bulk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkTimeSlotUpdateToJSON(requestParameters.bulkTimeSlotUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkTimeSlotOutputDtoFromJSON(jsonValue));
    }

    /**
     */
    async pauseControllerUpdateBulkTimeSlot(requestParameters: PauseControllerUpdateBulkTimeSlotRequest, initOverrides?: RequestInit): Promise<BulkTimeSlotOutputDto> {
        const response = await this.pauseControllerUpdateBulkTimeSlotRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async pauseControllerUpdateSingleTimeSlotRaw(requestParameters: PauseControllerUpdateSingleTimeSlotRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.singleTimeSlotUpdate === null || requestParameters.singleTimeSlotUpdate === undefined) {
            throw new runtime.RequiredError('singleTimeSlotUpdate','Required parameter requestParameters.singleTimeSlotUpdate was null or undefined when calling pauseControllerUpdateSingleTimeSlot.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pause/timeslots/single`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SingleTimeSlotUpdateToJSON(requestParameters.singleTimeSlotUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pauseControllerUpdateSingleTimeSlot(requestParameters: PauseControllerUpdateSingleTimeSlotRequest, initOverrides?: RequestInit): Promise<void> {
        await this.pauseControllerUpdateSingleTimeSlotRaw(requestParameters, initOverrides);
    }

    /**
     */
    async paymentControllerGetIssuersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/payment/issuers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async paymentControllerGetIssuers(initOverrides?: RequestInit): Promise<void> {
        await this.paymentControllerGetIssuersRaw(initOverrides);
    }

    /**
     */
    async paymentControllerGetIssuersListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/payment/issuerslist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async paymentControllerGetIssuersList(initOverrides?: RequestInit): Promise<void> {
        await this.paymentControllerGetIssuersListRaw(initOverrides);
    }

    /**
     */
    async paymentControllerPaymentWebhookRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/payment/webhook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async paymentControllerPaymentWebhook(initOverrides?: RequestInit): Promise<void> {
        await this.paymentControllerPaymentWebhookRaw(initOverrides);
    }

    /**
     */
    async paymentControllerRedirectUserRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/payment/redirect/{id}`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async paymentControllerRedirectUser(initOverrides?: RequestInit): Promise<void> {
        await this.paymentControllerRedirectUserRaw(initOverrides);
    }

    /**
     */
    async productControllerGetProductRaw(requestParameters: ProductControllerGetProductRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AllProductOutputDto>> {
        if (requestParameters.category === null || requestParameters.category === undefined) {
            throw new runtime.RequiredError('category','Required parameter requestParameters.category was null or undefined when calling productControllerGetProduct.');
        }

        const queryParameters: any = {};

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/product`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllProductOutputDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerGetProduct(requestParameters: ProductControllerGetProductRequest, initOverrides?: RequestInit): Promise<AllProductOutputDto> {
        const response = await this.productControllerGetProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async shippingMethodsControllerGetShippingMethodsRaw(requestParameters: ShippingMethodsControllerGetShippingMethodsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShippingOutputDto>> {
        if (requestParameters.zipcode === null || requestParameters.zipcode === undefined) {
            throw new runtime.RequiredError('zipcode','Required parameter requestParameters.zipcode was null or undefined when calling shippingMethodsControllerGetShippingMethods.');
        }

        if (requestParameters.number === null || requestParameters.number === undefined) {
            throw new runtime.RequiredError('number','Required parameter requestParameters.number was null or undefined when calling shippingMethodsControllerGetShippingMethods.');
        }

        if (requestParameters.orderType === null || requestParameters.orderType === undefined) {
            throw new runtime.RequiredError('orderType','Required parameter requestParameters.orderType was null or undefined when calling shippingMethodsControllerGetShippingMethods.');
        }

        if (requestParameters.even === null || requestParameters.even === undefined) {
            throw new runtime.RequiredError('even','Required parameter requestParameters.even was null or undefined when calling shippingMethodsControllerGetShippingMethods.');
        }

        if (requestParameters.changeFlow === null || requestParameters.changeFlow === undefined) {
            throw new runtime.RequiredError('changeFlow','Required parameter requestParameters.changeFlow was null or undefined when calling shippingMethodsControllerGetShippingMethods.');
        }

        if (requestParameters.twoWeekly === null || requestParameters.twoWeekly === undefined) {
            throw new runtime.RequiredError('twoWeekly','Required parameter requestParameters.twoWeekly was null or undefined when calling shippingMethodsControllerGetShippingMethods.');
        }

        const queryParameters: any = {};

        if (requestParameters.zipcode !== undefined) {
            queryParameters['zipcode'] = requestParameters.zipcode;
        }

        if (requestParameters.number !== undefined) {
            queryParameters['number'] = requestParameters.number;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.even !== undefined) {
            queryParameters['even'] = requestParameters.even;
        }

        if (requestParameters.changeFlow !== undefined) {
            queryParameters['changeFlow'] = requestParameters.changeFlow;
        }

        if (requestParameters.twoWeekly !== undefined) {
            queryParameters['twoWeekly'] = requestParameters.twoWeekly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/shipping/methods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShippingOutputDtoFromJSON(jsonValue));
    }

    /**
     */
    async shippingMethodsControllerGetShippingMethods(requestParameters: ShippingMethodsControllerGetShippingMethodsRequest, initOverrides?: RequestInit): Promise<ShippingOutputDto> {
        const response = await this.shippingMethodsControllerGetShippingMethodsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statusControllerGetStatusRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async statusControllerGetStatus(initOverrides?: RequestInit): Promise<void> {
        await this.statusControllerGetStatusRaw(initOverrides);
    }

    /**
     */
    async subscriptionControllerCancelSubscriptionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/subscription/cancel/{token}`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async subscriptionControllerCancelSubscription(initOverrides?: RequestInit): Promise<void> {
        await this.subscriptionControllerCancelSubscriptionRaw(initOverrides);
    }

    /**
     */
    async subscriptionControllerChangeSubscriptionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/subscription/change`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async subscriptionControllerChangeSubscription(initOverrides?: RequestInit): Promise<void> {
        await this.subscriptionControllerChangeSubscriptionRaw(initOverrides);
    }

    /**
     */
    async subscriptionControllerCheckEmailHasNoSubscriptionRaw(requestParameters: SubscriptionControllerCheckEmailHasNoSubscriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerEmailOutputDto>> {
        if (requestParameters.customerEmailInputDto === null || requestParameters.customerEmailInputDto === undefined) {
            throw new runtime.RequiredError('customerEmailInputDto','Required parameter requestParameters.customerEmailInputDto was null or undefined when calling subscriptionControllerCheckEmailHasNoSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/subscription/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerEmailInputDtoToJSON(requestParameters.customerEmailInputDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerEmailOutputDtoFromJSON(jsonValue));
    }

    /**
     */
    async subscriptionControllerCheckEmailHasNoSubscription(requestParameters: SubscriptionControllerCheckEmailHasNoSubscriptionRequest, initOverrides?: RequestInit): Promise<CustomerEmailOutputDto> {
        const response = await this.subscriptionControllerCheckEmailHasNoSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async subscriptionControllerGetSubscriptionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/subscription/{token}`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async subscriptionControllerGetSubscription(initOverrides?: RequestInit): Promise<void> {
        await this.subscriptionControllerGetSubscriptionRaw(initOverrides);
    }

    /**
     */
    async subscriptionControllerSetChangeUrlsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/subscription/airtable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async subscriptionControllerSetChangeUrls(initOverrides?: RequestInit): Promise<void> {
        await this.subscriptionControllerSetChangeUrlsRaw(initOverrides);
    }

    /**
     */
    async subscriptionControllerSubscriptionWebhookRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/subscription/webhook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async subscriptionControllerSubscriptionWebhook(initOverrides?: RequestInit): Promise<void> {
        await this.subscriptionControllerSubscriptionWebhookRaw(initOverrides);
    }

    /**
     */
    async subscriptionControllerUpdateOrderRaw(requestParameters: SubscriptionControllerUpdateOrderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling subscriptionControllerUpdateOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/subscription/order/{token}`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async subscriptionControllerUpdateOrder(requestParameters: SubscriptionControllerUpdateOrderRequest, initOverrides?: RequestInit): Promise<void> {
        await this.subscriptionControllerUpdateOrderRaw(requestParameters, initOverrides);
    }

    /**
     */
    async subscriptionControllerUpdatePaymentFrequencyRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/subscription/frequency/{token}/{frequency}`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async subscriptionControllerUpdatePaymentFrequency(initOverrides?: RequestInit): Promise<void> {
        await this.subscriptionControllerUpdatePaymentFrequencyRaw(initOverrides);
    }

    /**
     */
    async subscriptionControllerUpdateShippingRaw(requestParameters: SubscriptionControllerUpdateShippingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling subscriptionControllerUpdateShipping.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/subscription/shipping/{token}`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async subscriptionControllerUpdateShipping(requestParameters: SubscriptionControllerUpdateShippingRequest, initOverrides?: RequestInit): Promise<void> {
        await this.subscriptionControllerUpdateShippingRaw(requestParameters, initOverrides);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ProductControllerGetProductCategoryEnum {
    NewCustomers = 'newCustomers',
    CurrentCustomers = 'currentCustomers',
    NewCustomersV2 = 'newCustomersV2',
    NewCustomersV3 = 'newCustomersV3',
    NewCustomersV4 = 'newCustomersV4',
    NewCustomersV5 = 'newCustomersV5'
}
/**
    * @export
    * @enum {string}
    */
export enum ShippingMethodsControllerGetShippingMethodsOrderTypeEnum {
    Single = 'single',
    Subscription = 'subscription'
}
/**
    * @export
    * @enum {string}
    */
export enum ShippingMethodsControllerGetShippingMethodsEvenEnum {
    Yes = 'yes',
    No = 'no'
}
/**
    * @export
    * @enum {string}
    */
export enum ShippingMethodsControllerGetShippingMethodsChangeFlowEnum {
    Yes = 'yes',
    No = 'no'
}
/**
    * @export
    * @enum {string}
    */
export enum ShippingMethodsControllerGetShippingMethodsTwoWeeklyEnum {
    Yes = 'yes',
    No = 'no'
}
