/* tslint:disable */
/* eslint-disable */
/**
 * Fruitboer shop api
 * Custom shop fruitboer api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShippingMethodOutputDto
 */
export interface ShippingMethodOutputDto {
    /**
     * 
     * @type {string}
     * @memberof ShippingMethodOutputDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingMethodOutputDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ShippingMethodOutputDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingMethodOutputDto
     */
    dayDescription: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingMethodOutputDto
     */
    orderTime: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingMethodOutputDto
     */
    momentDay: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingMethodOutputDto
     */
    deliveryDay: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingMethodOutputDto
     */
    shippingDate: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingMethodOutputDto
     */
    visibility: string;
}

export function ShippingMethodOutputDtoFromJSON(json: any): ShippingMethodOutputDto {
    return ShippingMethodOutputDtoFromJSONTyped(json, false);
}

export function ShippingMethodOutputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingMethodOutputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'price': json['price'],
        'dayDescription': json['dayDescription'],
        'orderTime': json['orderTime'],
        'momentDay': json['momentDay'],
        'deliveryDay': json['deliveryDay'],
        'shippingDate': json['shippingDate'],
        'visibility': json['visibility'],
    };
}

export function ShippingMethodOutputDtoToJSON(value?: ShippingMethodOutputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'price': value.price,
        'dayDescription': value.dayDescription,
        'orderTime': value.orderTime,
        'momentDay': value.momentDay,
        'deliveryDay': value.deliveryDay,
        'shippingDate': value.shippingDate,
        'visibility': value.visibility,
    };
}

